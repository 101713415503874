@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Sora:wght@600;700&family=Ubuntu:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  color: #000000;
  background: #ffffff;
  font-family: "Lato", sans-serif;
}

main {
  box-sizing: border-box;
}

.headingStyle {
  font-family: "Sora", sans-serif;
}

.buttonStyle {
  font-family: "Ubuntu", sans-serif;
}

.signupParent::before {
  content: "";
  background-image: url("../public/signup-bg.png");
  opacity: 0.3;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.loginParent::before {
  content: "";
  background-image: url("../public/login-bg.png");
  opacity: 0.3;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
